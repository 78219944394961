<template>
  <!-- 新的添加素材弹窗 -->
  <el-dialog class="newUploadImg" :visible.sync="dialogVisible" width="1080px" @close="$emit('changeStatus', false)"
    :append-to-body="true">
    <template #title>
      <div class="tabsBox">
        <div @click="handleClick(0)" :class="['tabsItem', nowStatus === 0 ? 'is_active' : '']">选择素材</div>
        <div @click="handleClick(1)" :class="['tabsItem', nowStatus === 1 ? 'is_active' : '']">
          正在上传（
          <span style="color: #409eff">{{ alreadySuccess.length }}</span>
          /{{ fileList.length }}）
        </div>
      </div>
    </template>
    <div class="contentBox" v-show="nowStatus === 0">
      <div class="left" v-if="type != 'live' && type != 'liveVideo' && type != 'file'">
        <LookGroup ref="LookGroup" :data="groupList" :styleD="{ height: '100%' }" @resultGroup="resultGroup">
        </LookGroup>
      </div>
      <div class="right">
        <div class="top">
          <div class="uploadInputBox">
            {{ type == 'video' || type == 'liveVideo' ? '上传视频' : type == 'file' ? '上传文件' : '上传图片' }}
            <input type="file" @input="getSourceFile($event)" :multiple="isMany" ref="uploadInput"
              class="uploadInput" />
          </div>
          <div>
            <el-select style="width: 150px; border-color: #dcdfe6" size="small" v-model="sortStyle" placeholder="请选择"
              @change="isLookImageFn">
              <el-option v-for="item in sortStyleList" :key="item.type" :label="item.name" :value="item.type">
              </el-option>
            </el-select>
            <el-input style="width: 200px; margin: 0 16px; border-color: #dcdfe6" type="text" v-model="searchName"
              size="small" prefix-icon="el-icon-search"></el-input>
            <el-button type="primary" size="small" @click="getImageList(-1, 1)">搜 索</el-button>
          </div>
        </div>
        <div class="bot" @scroll="onLoadNextRow($event)">
          <No v-if="!contentList.length" />
          <div class="fileList" v-if="type == 'file'">
            <div :style="{ backgroundColor: item.isSelect ? '#ecf5ff' : '' }" class="fileItem" @click="chooseItem(item)"
              v-for="(item, index) in contentList" :key="index">
              <img src="@/assets/image/fileFrant.png" alt="" />
              <div class="fileName">{{ item.name }}</div>
              <div>{{ item.showSize }}</div>
            </div>
          </div>
          <div v-else class="soureListBox">
            <div class="soureItem" @click="chooseItem(item)" v-for="(item, index) in contentList" :key="index">
              <div class="content" :style="{ border: item.isSelect ? '1px solid #409eff' : '' }">
                <img v-if="type == 'image' || type == 'live'" :src="item.url" alt="" />
                <div v-else>
                  <img v-if="item.cover" :src="item.cover" alt="" />
                  <video v-else :src="item.url"></video>
                </div>
                <div v-if="type === 'image' || type === 'live'" class="showSize">{{ item.width }}*{{ item.height }}
                </div>
                <div v-if="type === 'video'" class="showSize">{{ item.showSize }}</div>
              </div>
              <div class="name">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="progressItemList" v-show="nowStatus === 1">
      <div v-for="(item, index) in fileList" :key="index" class="progressItem">
        <div class="left">{{ item.name }}</div>
        <div class="mid">{{ item.size }}</div>
        <div class="right">
          <div v-show="item.error">
            <img src="@/assets/image/uploadImgError.png" alt="" />
            {{ item.errorDetail }}
          </div>
          <!-- <div v-show="item.percentage === 100"><img src="@/assets/image/uploadImgSuccess.png" alt="" />上传成功</div> -->
          <!-- v-show="item.percentage !== 100 && !item.error" -->
          <div v-show="!item.error">
            <el-progress :text-inside="true" :stroke-width="12" :percentage="item.percentage"></el-progress>
          </div>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <div class="footerBox">
        <el-button @click="goSourceCenter">进入素材中心</el-button>
        <div v-show="nowStatus === 0">
          <el-button @click="$emit('changeStatus', false)">取 消</el-button>
          <el-button :disabled="isSelectList.length == 0" type="primary" @click="sureSoureList">确 定</el-button>
        </div>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import LookGroup from '@/components/lookGroup.vue';
import { getfilesize, getSourceWidthHeight } from '@/util/util.js';
export default {
  components: {
    LookGroup,
  },
  props: {
    // 弹框是否关闭
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    /**
     * 类别判断
     * 0-站点内正常上传图片
     * 1-站点外上传图片
     * 2-直播上传图片素材
     * 3-企业微信上传素材
     * 4-视频号商品类目资质图片上传
     */
    url: {
      type: Number,
      default: 0,
    },
    // 当允许多选时 传入的素材的列表
    manyPath: {
      type: Array,
      default: () => [],
    },
    // 是否开始多选
    isMany: {
      type: Boolean,
      default: false,
    },
    // 多选的最大数量
    isManyMax: {
      type: Number,
      default: null,
    },
    // 当前默认选中的素材分组
    nowGroup: {
      type: Object,
      default: () => {
        return {
          id: -1,
          name: '全部图片',
        };
      },
    },
    // 素材类型 image - video
    type: {
      type: String,
      default: 'image',
    },
    // 素材最大的size
    maxSize: {
      type: Number,
      default: 0,
    },
    //
    liveStatus: {
      type: Number,
      default: 0,
    },
    // 特殊参数 需要就传 不需要就不传
    temporary_type: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    alreadySuccess () {
      return this.fileList.filter(item => item.percentage == 100);
    },
  },
  data () {
    return {
      nowStatus: 0,
      groupList: [],
      sortStyle: 0,
      sortStyleList: [
        { type: 0, name: '最新上传在前' },
        { type: 1, name: '最新上传在后' },
        { type: 2, name: '按图片名升序' },
        { type: 3, name: '按图片名降序' },
      ],
      fileList: [],
      searchName: '',
      contentList: [],
      page: 1,
      rows: 40,
      total: 0,
      isClick: false,
      isSelectList: [],
      id: 1,
    };
  },
  methods: {
    // 切换图片排序
    isLookImageFn (val) {
      this.getImageList();
    },
    // 递归数组
    dealFnArr (arr, callback = () => { }) {
      arr.map(item => {
        callback(item);
        if (item._child) {
          this.dealFnArr(item._child, callback);
        }
      });
    },
    goSourceCenter () {
      let routeData = this.$router.resolve('/set/sourceCenter');
      window.open(routeData.href, '_blank');
    },
    // 获取切换的节点
    resultGroup (data) {
      this.nowGroup = data;
      this.page = 1;
      this.getImageList(this.nowGroup.id);
    },
    handleClick (status) {
      this.nowStatus = status;
    },
    // 获取图片分组
    getGroupList () {
      let that = this;
      this.$axios.post(this.$api.sourceCenter.ImagesGroupList).then(res => {
        if (res.code == 0) {
          that.groupList = res.result.list;
          that.dealFnArr(that.groupList, item => {
            that.$set(item, 'checked', false);
          });
          this.$nextTick(() => {
            this.$refs.LookGroup.setDefaule(this.nowGroup);
          });
        } else {
          that.$message.error(res.msg);
        }
      });
    },
    // 获取要上传的图片
    getSourceFile ($event) {
      let files = $event.target.files;
      let arr = [];
      if (this.type == 'image' || this.type == 'live') {
        arr = ['.png', '.jpg', '.jpeg', '.gif'];
      } else if (this.type == 'file') {
        arr = [];
      } else {
        arr = ['.mp4', '.3gp', 'm3u8'];
      }
      let maxSize = null;
      if (this.maxSize) {
        maxSize = this.maxSize;
      } else {
        maxSize = this.type == 'video' ? 1024 * 1024 * 20 : 1024 * 1024 * 5;
      }
      for (const key in files) {
        if (!isNaN(Number(key))) {
          // 文件名最后一个.的索引
          let str = files[key].name.lastIndexOf('.');
          // 文件的后缀名 ->.mp3
          let lastStr = files[key].name.substr(str).toLowerCase();

          let res = arr.findIndex(item1 => item1 == lastStr);

          if (this.type != 'file') {
            if (res == -1) {
              this.$message({
                message: `${files[key].name}不符合上传类型，已从上传队列删除`,
                type: 'error',
                customClass: 'mzindex',
              });
              continue;
            }
          }
          if (this.type == 'file') {
            // 如果是上传文件 需另外计算文件最大值
            if (lastStr === '.jpg' || lastStr === '.png' || lastStr === '.mp4') {
              maxSize = 1024 * 1024 * 10;
            } else if (lastStr === '.amr') {
              maxSize = 1024 * 1024 * 2;
            } else {
              maxSize = 1024 * 1024 * 20;
            }
          }
          if (files[key].size > maxSize) {
            this.$message({
              message: `${files[key].name}超过此处最大传输(${getfilesize(maxSize)})限制，已从上传队列删除`,
              type: 'error',
              customClass: 'mzindex',
            });
            continue;
          }
          if (files[key].size < 5) {
            this.$message({
              message: `文件size必须大于5个字节`,
              type: 'error',
              customClass: 'mzindex',
            });
            continue;
          }
          this.fileList.push({
            name: files[key].name,
            size: getfilesize(files[key].size),
            file: files[key],
            percentage: 0,
            error: false,
            errorDetail: '',
          });
        }
      }
      if (this.fileList.length) {
        this.startUpload();
      } else {
        this.$refs.uploadInput.value = '';
      }
    },
    async startUpload () {
      let that = this;
      this.nowStatus = 1;
      for (let i = 0; i < this.fileList.length; i++) {
        await this.uploadImg(this.fileList[i]).then(async res => {
          this.fileList[i].percentage = 100;
          let resObj = {
            id: res.id,
            name: this.fileList[i].name,
            type: this.type,
            url: res.path,
            isSelect: true,
          };
          let chooseObj = {
            id: res.id,
            name: this.fileList[i].name,
            type: this.type,
            path: res.path,
            isSelect: false,
          };
          if (this.type == 'image' || this.type == 'live') {
            let { width, height } = await getSourceWidthHeight(this.url !== 4 ? res.path : res.img_info.temp_img_url);
            that.$set(resObj, 'width', width);
            that.$set(resObj, 'height', height);
          } else {
            that.$set(resObj, 'showSize', this.fileList[i].size);
            that.$set(chooseObj, 'showSize', this.fileList[i].size);
          }
          if (this.type === 'video') {
            that.$set(resObj, 'cover', res.cover);
            that.$set(chooseObj, 'cover', res.cover);
          }
          if (this.type == 'file') {
            resObj.media_id = res.media_id;
            resObj.url = res.path;
            resObj.id = this.id;
            chooseObj.media_id = res.media_id;
            chooseObj.id = this.id;
            this.id++;
          }
          if (this.url === 2 || this.url === 3) {
            resObj.media_id = res.media_id;
            chooseObj.media_id = res.media_id;
            resObj.id = this.id;
            chooseObj.id = this.id;
            this.id++;
          }
          if (this.url === 4) {
            resObj.url = res.img_info.temp_img_url;
            chooseObj.path = res.img_info.temp_img_url;
            resObj.id = this.id;
            chooseObj.id = this.id;
            this.id++;
          }
          if (!this.isMany) {
            // 单选情况
            if (this.isSelectList.length > 0) {
              let id = this.isSelectList[0].id;
              let nowSelect = this.contentList.find(item => item.id === id);
              nowSelect.isSelect = false;
              this.isSelectList = [];
              if (this.type === 'live' && this.liveStatus == 1) {
                if (Number(resObj.width) > 300 || Number(resObj.height) > 300) {
                  resObj.isSelect = false;
                  this.contentList.unshift(resObj);
                  this.$message({
                    message: '图片宽高不符合要求,已取消自动选中',
                    type: 'warning',
                    customClass: 'mzindex',
                  });
                  return;
                }
              }
              this.contentList.unshift(resObj);
              this.isSelectList.push(chooseObj);
            } else {
              if (this.type === 'live' && this.liveStatus == 1) {
                if (Number(resObj.width) > 300 || Number(resObj.height) > 300) {
                  resObj.isSelect = false;
                  this.contentList.unshift(resObj);
                  this.$message({
                    message: '微信限制直播商品图片宽高不可超过300px',
                    type: 'warning',
                    customClass: 'mzindex',
                  });
                  return;
                }
              }
              this.contentList.unshift(resObj);
              this.isSelectList.push(chooseObj);
            }
          } else {
            let checkRes = this.checkMaxChoose(2);
            if (checkRes) {
              this.contentList.unshift(resObj);
              this.isSelectList.push(chooseObj);
            } else {
              resObj.isSelect = false;
              this.contentList.unshift(resObj);
            }
          }
        });
      }

      this.$message({
        message: `上传成功`,
        type: 'success',
      });
      setTimeout(() => {
        this.nowStatus = 0;
        this.fileList = [];
        this.$refs.uploadInput.value = '';
      }, 1000);
    },
    // 调用接口上传图片
    uploadImg (item) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('files', item.file);
        console.log(999, item.file);
        if (this.url === 2) {
          formData.append('type', 'live');
        } else if (this.url === 3) {
          formData.append('type', 'wxwork');
        }
        if (this.temporary_type === 1) {
          formData.append('temporary_type', 1);
        }
        // 设置分组
        if (this.nowGroup.id !== -2 && this.nowGroup.id !== -1) {
          formData.append('group_id', this.nowGroup.id);
        }
        
        this.$axios
          .showProgressUpload(this.url === 1 ? this.$api.user.SystemUpload : this.url === 4 ? this.$api.set.videoNumber.imgUpload : this.$api.user.addFiles, formData, data => {
            let { loaded, total } = data;
            console.log(777, formData);
            let nowPercentage = Math.floor((loaded / total) * 100) - 10 >= 0 ? Math.floor((loaded / total) * 100) - 10 : 0;
            this.$set(item, 'percentage', nowPercentage);
          })
          .then(res => {
            if (res.code == 0) {
              resolve(res.result);
            } else {
              this.fileList = [];
              this.nowStatus = 0;
              this.$nextTick(() => {
                this.$refs.uploadInput.value = '';
              });
              this.$message.error(res.msg);
            }
          })
          .catch(() => {
            this.fileList = [];
            this.nowStatus = 0;
            this.$nextTick(() => {
              this.$refs.uploadInput.value = '';
            });
          });
      });
    },
    // 查询当前符合条件的图片
    getImageList (id, status) {
      if (this.type == 'live' || this.type == 'liveVideo' || this.type == 'file') {
        this.$message({
          message: '请上传图片',
          type: 'warning',
          customClass: 'mzindex',
        });
        return;
      }
      // status 1-> 点击搜索按钮  2->滑动分页查询
      let that = this;
      let obj = {
        page: this.page,
        rows: this.rows,
        type: this.type,
        is_image: 1,
      };
      if (status == 1) {
        // 清空树形结构高亮
        this.isClick = true;
        this.nowGroup.id = -2;
        this.$nextTick(() => {
          this.$refs.LookGroup.setDefaule(null);
        });
        this.page = 1;
      }
      // 如果需要查询指定分组
      if (id != -1) {
        obj.group_id = id;
      }
      if (this.searchName) {
        obj.keywords = this.searchName;
      }
      switch (this.sortStyle) {
        case 0:
          obj.order_type = ['create_time', 'desc'];
          break;
        case 1:
          obj.order_type = ['create_time', 'asc'];
          break;
        case 2:
          obj.order_type = ['name', 'desc'];
          break;
        case 3:
          obj.order_type = ['name', 'asc'];
          break;
        default:
          break;
      }
      this.$axios.post(this.$api.user.imgList, obj).then(res => {
        if (res.code == 0) {
          that.total = res.result.total_number;
          res.result.list.map(async item => {
            this.$set(item, 'isSelect', false);
            if (that.type == 'image') {
              let { width, height } = await getSourceWidthHeight(item.url);
              that.$set(item, 'width', width);
              that.$set(item, 'height', height);
            } else {
              that.$set(item, 'showSize', getfilesize(item.size));
            }
          });
          if (status === 2) {
            // 分页
            that.contentList.push(...res.result.list);
          } else {
            that.isSelectList = [];
            that.contentList = res.result.list;
          }
        }
      });
    },
    // 元素滑动到底部搜索下一页
    onLoadNextRow ($event) {
      const { scrollTop, clientHeight, scrollHeight } = $event.target;
      if (scrollTop + clientHeight === scrollHeight) {
        if (this.page * this.rows <= this.total) {
          this.page++;
          if (this.nowGroup.id !== -2) {
            // 树形菜单有高亮
            this.getImageList(this.nowGroup.id, 2);
          } else {
            this.getImageList(-1, 2);
          }
        }
      }
    },
    checkMaxChoose (status) {
      if (this.isManyMax) {
        // 有最大选择数量限制
        if (this.isSelectList.length === this.isManyMax - this.manyPath.length) {
          let str = status == 1 ? `已到素材选中量上限` : '已到最大选中数量,取消自动选中上传图片';
          this.$message({
            message: str,
            type: 'warning',
            customClass: 'mzindex',
          });
          return false;
        }
      }
      return true;
    },
    // 更改图片的选中状态
    chooseItem (item) {
      if (!item.isSelect) {
        if (!this.checkMaxChoose(1)) {
          return;
        }
      }
      if (this.type == 'live' && this.liveStatus == 1) {
        if (item.width > 300 || item.height > 300) {
          this.$message({
            message: '微信限制直播商品图片宽高不可超过300px',
            type: 'warning',
            customClass: 'mzindex',
          });
          return;
        }
      }
      if (this.maxSize !== 0) {
        if (this.maxSize < item.size) {
          this.$message({
            message: '该素材大小超过此处最大允许的最大图片大小',
            type: 'warning',
            customClass: 'mzindex',
          });
          return;
        }
      }
      item.isSelect = !item.isSelect;
      if (this.isMany) {
        // 可以多选
        if (item.isSelect) {
          let itemThis = JSON.parse(JSON.stringify(item));
          let obj = {
            id: itemThis.id,
            path: itemThis.url,
            name: itemThis.name,
            isSelect: false,
          };
          if (this.type === 'video') {
            obj.cover = itemThis.cover;
          }
          this.isSelectList.push(obj);
        } else {
          let index = this.isSelectList.findIndex(item1 => item1.id === item.id);
          this.isSelectList.splice(index, 1);
        }
      } else {
        this.contentList.map(item1 => {
          if (item1.id !== item.id) {
            item1.isSelect = false;
          }
        });
        if (item.isSelect) {
          let itemThis = JSON.parse(JSON.stringify(item));
          let obj = {
            id: itemThis.id,
            path: itemThis.url,
            name: itemThis.name,
            isSelect: false,
          };
          if (this.type === 'video') {
            obj.cover = itemThis.cover;
          }
          this.isSelectList = [obj];
        } else {
          this.isSelectList = [];
        }
      }
    },
    sureSoureList () {
      if (this.isSelectList.length > 0) {
        this.$emit('getSource', this.isSelectList);
        this.$emit('changeStatus', false);
      }
    },
  },
  watch: {
    dialogVisible: {
      handler (val) {
        if (val) {
          if (this.type != 'live' && this.type != 'liveVideo' && this.type != 'file') {
            this.getGroupList();
            this.getImageList(-1);
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>
.tabsBox {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f3f5;

  .tabsItem {
    margin-right: 40px;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 12px;
  }

  .is_active {
    position: relative;

    &::after {
      width: 100%;
      height: 1px;
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      background-color: #409eff;
    }
  }
}

.contentBox {
  display: flex;
  align-items: center;

  .left {
    flex: 0 0 220px;
    flex-shrink: 0;
    height: 490px;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: auto;
    border: 1px solid #ccc;
  }

  .right {
    flex: 1;
    height: 490px;
    margin-left: 12px;
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      justify-content: space-between;
      height: 32px;

      .uploadInputBox {
        width: 80px;
        height: 32px;
        background: #409eff;
        border-radius: 4px;
        color: #fff;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
        position: relative;

        .uploadInput {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          cursor: pointer;
        }
      }

      .el-input,
      .el-select {
        /deep/ .el-input__inner {
          border-color: #dcdfe6 !important;
        }
      }
    }

    .bot {
      flex: 1;
      width: 100%;
      margin-top: 16px;

      overflow: hidden;
      overflow-y: auto;

      .fileList {
        width: 100%;

        .fileItem {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          padding: 0 20px;
          line-height: 40px;

          img {
            margin-right: 10px;
          }

          .fileName {
            width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .soureListBox {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }

      .soureItem {
        display: flex;
        flex-direction: column;
        margin: 0 10px 20px 0;

        .content {
          width: 88px;
          height: 88px;
          position: relative;
          background: #f8f9fb;
          border: 1px solid #f2f3f5;

          &>div {
            height: 100%;
            width: 100%;
          }

          img,
          video {
            width: 100% !important;
            height: 100% !important;
            object-fit: contain;
          }

          .showSize {
            width: 100%;
            height: 0;
            background-color: rgba(51, 51, 51, 0.4);
            color: #fff;
            position: absolute;
            left: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.3s;
            overflow: hidden;
          }

          &:hover .showSize {
            height: 20px;
          }
        }

        .name {
          width: 70px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
        }
      }
    }
  }
}

.footerBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progressItemList {
  min-height: 350px;
}

.progressItem {
  display: flex;
  align-items: center;
  padding-bottom: 20px;

  .left {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
  }

  .mid {
    width: 120px;
  }

  .right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &>div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .el-progress {
      width: 60%;
      line-height: 12px;
    }
  }

  &:not(:first-child) {
    padding: 20px 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #f2f3f5;
  }
}
</style>
